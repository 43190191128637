const _paTranslations = {
  nav_language: 'ਭਾਸ਼ਾ',
  nav_subtext : '27K+ ਕਿਸਾਨਾਂ ਦਾ ਪਸੰਦੀਦਾ ਐਪ',
  nav_call: 'ਸਾਨੂੰ ਸਵੇਰੇ 8 ਵਜੇ ਤੋਂ ਸ਼ਾਮ 7 ਵਜੇ ਦੇ ਵਿਚਕਾਰ ਕਾਲ ਕਰੋ',
  nav_search_placeholder: 'ਮੱਝ, ਬਿਨੋਲਾ ਖਾਲ, ਸਿਲੇਜ ਲੱਭੋ',
  nav_get: "ਪਹਿਲੇ ਆਰਡਰ 'ਤੇ ₹100 ਦੀ ਛੋਟ",
  nav_download_app: 'ਐਪ ਡਾਊਨਲੋਡ ਤੇ',
  lakh_downloads: 'ਇੱਕ ਮਿਲੀਅਨ ਤੋਂ ਵੱਧ ਡਾਉਨਲੋਡਸ',
  we_offer_sub: 'ਸਾਡੀ ਸੇਵਾਵਾਂ',
  we_offer_title: 'ਸਾਡੇ ਤੋਂ ਕਿਉਂ ਖਰੀਦੋ',
  downloads: 'ਡਾਊਨਲੋਡ"',

  download_mob: 'ਡਾਊਨਲੋਡ ਕਰੋ ਅਤੇ ਪਹਿਲੇ ਆਰਡਰ ਤੇ ₹100 ਦੀ ਛੋਟ ਪ੍ਰਾਪਤ ਕਰੋ',
  rating: 'ਰੇਟਿੰਗ',
  rating_on_google_playStore:'ਰੇਟਿੰਗ',

  home: 'ਹੋਮ ',
  we_offer_one_title: 'ਤੇਜ਼ ਹੋਮ ਡਿਲੀਵਰੀ',
  we_offer_one_desc:
    'ਸਾਡੇ ਨਜ਼ਦੀਕੀ ਕੇਂਦਰਾਂ ਤੋਂ 50 ਕਿ.ਮੀ. 24 ਘੰਟਿਆਂ ਦੇ ਅੰਦਰ ਡਿਲਿਵਰੀ।',
  we_offer_two_title: 'ਗੁਣਵੱਤਾ ਜਾਂਚ',
  we_offer_two_desc:
    'ਨਿਯਮਤ ਗੁਣਵੱਤਾ ਜਾਂਚ ਦੇ ਨਾਲ ਤਜ਼ਰਬੇਕਾਰ ਟੀਮਾਂ ਦੁਆਰਾ ਬਣਾਏ ਗਏ ਵਧੀਆ ਗੁਣਵੱਤਾ ਵਾਲੇ ਉਤਪਾਦ।',
  we_offer_three_title: 'ਗੁਣਵੱਤਾ ਵਾਲੇ ਉਤਪਾਦ।',
  we_offer_three_desc:
    'ਪ੍ਰੀਮੀਅਮ ਕੁਆਲਿਟੀ ਦੀ ਤਾਜ਼ਾ ਪਸ਼ੂ ਫੀਡ ਫੈਕਟਰੀ ਤੋਂ ਸਿੱਧੇ ਤੁਹਾਡੇ ਘਰ ਤੱਕ ਪਹੁੰਚਾਈ ਜਾਂਦੀ ਹੈ।',
  we_offer_four_title: "ਡਿਲੀਵਰੀ 'ਤੇ ਨਕਦ",
  we_offer_four_desc:
    "ਬਿਨਾਂ ਕਿਸੇ ਪਰੇਸ਼ਾਨੀ ਦੇ ਤੁਹਾਡੇ ਘਰ ਦੇ ਦਰਵਾਜ਼ੇ 'ਤੇ ਉਤਪਾਦ ਡਿਲੀਵਰ ਹੋਣ ਤੋਂ ਬਾਅਦ ਨਕਦ ਭੁਗਤਾਨ ਕਰੋ।",
  buy_cattle_feed_sub: 'ਪਸ਼ੂ ਫੀਡ ਖਰੀਦੋ',
  buy_cattle_feed_title:
    'ਤੁਹਾਡੇ ਪਸ਼ੂਆਂ ਲਈ <a>ਪ੍ਰੀਮੀਅਮ ਕੁਆਲਿਟੀ ਫੀਡ"</a>  <br/>ਤੋਂ ਸ਼ੁਰੂ - ਸਿਰਫ਼ <a>₹260</a>',
  buy_bhains: 'ਮੱਝ ਖਰੀਦੋ',
  purachase_cattle: 'ਮੱਝ ਖਰੀਦੋ',
  combo_offer: "ਕੌਮਬੋ ਔਫਰ",
  explore_categories:"Explore Categories",
  explore_categories_one:"Explore Categories",
  combo_sub:"ਵੱਧ ਤੋਂ ਵੱਧ ਬੱਚਤਾਂ ਲਈ ਕੰਬੋਜ਼",
  khal: 'ਖਾਲ',
  binola_khal: 'ਬਿਨੋਲਾ ਖਾਲ',
  binola_khal_sub: 'ਪ੍ਰੋਟੀਨ ਅਤੇ ਚਰਬੀ ਦਾ ਸਭ ਤੋਂ ਵਧੀਆ ਸਰੋਤ',
  sarso_khal: 'ਸਰ੍ਹੋਂ ਦਾ ਬੀਜ',
  sarso_khal_sub: 'ਉੱਚ ਪ੍ਰੋਟੀਨ ਖੁਰਾਕ',
  goli_khal: 'ਗੋਲੀ ਖਲ',
  goli_khal_sub: 'ਪੂਰੀ ਸੰਤੁਲਿਤ ਖੁਰਾਕ',
  mineral_mix: 'ਖਣਿਜ ਮਿਸ਼ਰਣ',
  mineral_mix_sub: 'ਪ੍ਰਜਨਨ ਸਿਹਤ ਅਤੇ ਵਿਕਾਸ ਲਈ ਮਦਦਗਾਰ',
  silage: 'ਸਾਈਲੇਜ',
  silage_sub: 'ਬਹੁਤ ਸਵਾਦ ਅਤੇ ਪੌਸ਼ਟਿਕ"',
  traditional_khal: 'ਰਵਾਇਤੀ ਪਸ਼ੂ ਫੀਡ',
  traditional_khal_sub:'ਫਾਈਬਰ ਅਤੇ ਊਰਜਾ ਦਾ ਸਭ ਤੋਂ ਵਧੀਆ ਸਰੋਤ',
  browse_all_cattle_feed: 'ਸਾਰੇ ਪਸ਼ੂ ਫੀਡ ਨੂੰ ਵੇਖੋ',
  vet_sub: 'ਕੀ ਤੁਹਾਡਾ ਪਸ਼ੂ ਕਿਸੇ ਸਮੱਸਿਆ ਤੋਂ ਪੀੜਤ ਹੈ?',
  vet_title: 'ਸਾਡੇ <span>ਤਜਰਬੇਕਾਰ ਵੈਟਰਨਰੀਅਨ</span> ਤੋਂ ਹੱਲ ਪ੍ਰਾਪਤ ਕਰੋ',
  vet_oncall: 'ਉਪਲਬਧ 24x7, ਕਾਲ',
  vet_atHome: 'ਘਰੇਲੂ ਸਲਾਹ ਉਪਲਬਧ ਹੈ',
  download_app: 'ਮੇਰਾ ਪਸ਼ੂ 360 ਐਪ ਡਾਊਨਲੋਡ ਕਰੋ',
  contact_us: 'ਸੰਪਰਕ ਕਰੋ',
  videos: 'ਵੀਡੀਓਜ਼',
  asked_answered: 'ਤੁਸੀਂ ਪੁੱਛਿਆ ਅਸੀਂ ਜਵਾਬ ਦਿੱਤਾ',
  go_to_youtube: "ਸਾਡੇ youtube ਚੈਨਲ 'ਤੇ ਜਾਓ",
  know: 'ਸਿੱਖੋ',
  customers_say: '<span>ਸਾਡੇ ਗਾਹਕ</span> ਕੀ ਕਹਿੰਦੇ ਹਨ',
  who_are_we: 'ਅਸੀਂ ਕੌਣ ਹਾਂ?',
  know_us: 'ਸਾਡੇ ਬਾਰੇ ਜਾਣੋ',
  about_us_desc:
    '130 ਲੋਕਾਂ ਦੀ ਇੱਕ ਟੀਮ ਇੱਕ ਤਕਨਾਲੋਜੀ-ਕਮ-ਮਟੀਰੀਅਲ ਪਲੇਟਫਾਰਮ ਬਣਾ ਕੇ ਡੇਅਰੀ ਕਿਸਾਨਾਂ ਦੇ ਜੀਵਨ ਨੂੰ ਬਿਹਤਰ ਬਣਾਉਣ ਲਈ ਨਿਕਲੀ ਹੈ ਜੋ ਹਰ ਛੋਟੇ ਡੇਅਰੀ ਫਾਰਮਰ ਨੂੰ ਚੰਗੀ ਗੁਣਵੱਤਾ ਵਾਲੇ ਪਸ਼ੂਆਂ, ਪੋਸ਼ਣ, ਪਸ਼ੂ ਚਿਕਿਤਸਕ ਸੇਵਾਵਾਂ ਅਤੇ ਸਿਹਤ ਸਲਾਹਾਂ ਤੱਕ ਪਹੁੰਚ ਪ੍ਰਦਾਨ ਕਰਦੀ ਹੈ ਅਤੇ ਚੀਜ਼ਾਂ ਨੂੰ ਤਿਆਰ ਕਰਦੀ ਹੈ। ਉਨ੍ਹਾਂ ਨੂੰ ਡੇਅਰੀ ਉਦਯੋਗ ਦਾ ਗਠਨ ਕਰਕੇ।',
  know_more: 'ਜਿਆਦਾ ਜਾਣੋ',
  kisan_served: 'ਕਿਸਾਨਾਂ ਦੀ ਸੇਵਾ ਕੀਤੀ',
  pin_code_served: 'ਪਿੰਨ ਕੋਡ \'ਤੇ ਡਿਲੀਵਰ ਕੀਤਾ ਗਿਆ',
  katte_sold: 'ਟੁਕੜੇ ਵੇਚੇ ਗਏ',
  buffalo_sold: 'ਮੱਝਾਂ ਵੇਚੀਆਂ', 
  app_downloads: 'ਐਪ ਡਾਊਨਲੋਡ',
  merapashu_app: 'ਮੇਰਾ ਪਸ਼ੂ 360 ਐਪ',
  download_app_title:
    'ਅੱਜ ਹੀ <a>ਮੇਰਾ ਪਸ਼ੂ 360 ਐਪ </a> ਡਾਊਨਲੋਡ ਕਰੋ <br/> <a>ਅਤੇ ₹100 ਪ੍ਰਾਪਤ ਕਰੋ</a>',
  browse_cattle: '<a>75+ ਪੈਮਾਨੈ ਤੇ ਜਾਂਚ ਕੀਤੀ ਗਈ ਮੁਰਾਹ ਮੱਝ ਵੇਖੋ</a>',
  buy_feed: '<a>ਸੰਤੁਲਿਤ ਪਸ਼ੂ ਫੀਡ ਖਰੀਦੋ</a>',
  sell_cattle: '<a>ਪਸ਼ੂ ਵੇਚੋ</a>',
  check_cattle_price: 'ਪਸ਼ੂ ਵੇਚੋ',
  get_vet: '<a>ਡਾਕਟਰ ਦੀ ਸਲਾਹ ਲਓ</a>',
  company: 'ਕੰਪਨੀ',
  about_us: 'ਸਾਡੇ ਬਾਰੇ',
  career: 'ਕਰੀਅਰ ਜਾਂ ਪੇਸ਼ੇ',
  blog: 'ਬਲੌਗ',
  meet_with_us: 'ਸਾਡੇ ਨਾਲ ਮਿਲੋ',
  faq: 'ਅਕਸਰ ਪੁੱਛੇ ਜਾਣ ਵਾਲੇ ਸਵਾਲ',
  explore: 'ਪਤਾ ਲਗਾਓ',
  Download_Button_sub:'ਗੂਗਲ ਪਲੇਅਸਟੋਰ ਤੇ <span>ਮੁਫਤ</span> ਉਪਲਬਧ ਹੈ',
  buy_cattle_feed: 'ਪਸ਼ੂ ਫੀਡ ਖਰੀਦੋ',
  buy_cattle: 'ਪਸ਼ੂ ਖਰੀਦੋ',
  buy_cattle_sub:"ਮੁਰਰਾ ਮੱਝ, ਐੱਚ.ਐੱਫ ਗਾਂ, ਸਾਹੀਵਾਲ",
  check_cattle_price_sub:"ਆਪਣੇ ਪਸ਼ੂਆਂ ਦੀ ਮਾਰਕੀਟ ਕੀਮਤ ਦੀ ਜਾਂਚ ਕਰੋ",
  sell_your_cattle: 'ਪਸੂ ਮੁੱਲ',
  hub_loc: 'ਖੇਤ ਦੀ ਸਥਿਤੀ',
  gurgaon: 'ਗੁੜਗਾਓਂ',
  jind: 'ਜੀਂਦ',
  matan: 'ਮਟਨ',
  ganganagar: 'ਸ਼੍ਰੀ ਗੰਗਾ ਨਗਰ"',
  sirsa: 'ਗੁੜਗਾਓਂ',
  address:"ਪਿੰਡ ਧੂਮਪੁਰ, ਨੇੜੇ ਸੈਕਟਰ 63, ਗੁੜਗਾਓਂ ਖੇਵਟ ਨੰ: 50, ਖਤੌਨੀ ਨੰ: 52, ਮਸਤੀਲ ਨੰ: 6, ਕਿਲਾ ਨੰ: 3, ਗੁੜਗਾਓਂ- 122102",
  delivering: 'ਪਸ਼ੂ ਫੀਡ ਡਿਲਿਵਰੀ',
  delhi: 'ਦਿੱਲੀ',
  haryana: 'ਹਰਿਆਣਾ',
  punjab: 'ਪੰਜਾਬ',
  rajasthan: 'ਰਾਜਸਥਾਨ',
  up: 'ਉੱਤਰ ਪ੍ਰਦੇਸ਼',
  privacy_policy: 'ਪਰਾਈਵੇਟ ਨੀਤੀ',
  cancellation_policy: 'ਰੱਦ ਕਰਨਾ ਨੀਤੀ',
  commerce_policy:'ਵਪਾਰ ਨੀਤੀ',
  copyright: 'ਕਾਪੀਰਾਈਟ {{year}} ਮੇਰਾ ਪਸ਼ੂ 360 ਲਿਮਿਟੇਡ',
  terms: 'ਵਰਤੋ ਦੀਆਂ ਸ਼ਰਤਾਂ',
  media_coverage: 'ਮੀਡੀਆ ਕਵਰੇਜ',
  force_behind: 'ਮੇਰਾ ਪਸ਼ੂ 360 ਦੇ ਪਿੱਛੇ ਚੱਲਣ ਵਾਲੀ ਸ਼ਕਤੀ',
  meet_founding_team: 'ਸੰਸਥਾਪਕ ਟੀਮ ਨੂੰ ਮਿਲੋ',
  divyanshu: 'ਦਿਵਯਾਂਸ਼ੂ ਟਾਂਬੇ',
  divyanshu_post: 'ਸਹਿ-ਸੰਸਥਾਪਕ ਅਤੇ ਮੁੱਖ ਵਪਾਰ ਅਧਿਕਾਰੀ',
  divyanshu_desc:
    'ਮੇਰਾ ਪਸ਼ੂ 360  ਡੇਅਰੀ ਕਿਸਾਨਾਂ ਨੂੰ ਆਪਣਾ ਕਾਰੋਬਾਰ ਸਫਲਤਾਪੂਰਵਕ ਚਲਾਉਣ ਵਿੱਚ ਮਦਦ ਕਰ ਰਿਹਾ ਹੈ। ਹਰੇਕ ਸਟਾਰਟ-ਅੱਪ ਨੂੰ ਉਹਨਾਂ ਦੀਆਂ ਨਾਜ਼ੁਕ ਅਤੇ ਜ਼ਰੂਰੀ ਲੋੜਾਂ ਨੂੰ ਪੂਰਾ ਕਰਨ ਲਈ ਸਮਰਥਨ ਕਰਦਾ ਹੈ।',
  niket: 'ਨਿਕੇਤ ਅਗਰਵਾਲ',
  niket_post: 'ਸਹਿ-ਸੰਸਥਾਪਕ ਅਤੇ ਸੀ.ਈ.ਓ',
  niket_desc:
    "ਭਾਰਤ ਦੇ ਸਭ ਤੋਂ ਹਾਸ਼ੀਏ 'ਤੇ ਪਏ 30% ਵੱਡੇ ਖੇਤੀ ਉਦਯੋਗ ਵਿੱਚ ਮੁੱਲ ਸਿਰਜਣ ਨੂੰ ਰਸਮੀ ਬਣਾਉਣ ਅਤੇ ਚਲਾਉਣ ਦੀ ਯਾਤਰਾ 'ਤੇ ਮੁੱਖ ਤਕਨੀਕੀ ਅਧਿਕਾਰੀ (ਸੀਟੀਓ)",
  varun: 'ਵਰੁਣ ਵਰਮਾ',
  varun_post: 'ਸਹਿ-ਸੰਸਥਾਪਕ ਅਤੇ ਮੁੱਖ ਤਕਨੀਕੀ ਅਧਿਕਾਰੀ (ਸੀਟੀਓ)',
  varun_desc: 'ਟੈਕਨੋਲੋਜੀ ਪ੍ਰਚਾਰਕ ਅਤੇ ਉਤਪਾਦ ਵਿਕਾਸ ਅਤੇ ਸ਼ੁਰੂਆਤ ਲਈ ਸਲਾਹਕਾਰ।',
  meet_leadership_team: 'ਲੀਡਰਸ਼ਿਪ ਟੀਮ ਨੂੰ ਮਿਲੋ',
  kanu: 'ਕਨੂਪ੍ਰਿਆ ਸਾਲਦੀ',
  kanu_post: 'ਸਹਿ-ਸੰਸਥਾਪਕ ਅਤੇ ਸੀ.ਓ.ਓ',
  kanu_desc:
    'ਸਿੱਧੇ ਅਤੇ ਅਸਿੱਧੇ ਖਰਚਿਆਂ ਵਿੱਚ ਖਪਤਕਾਰ ਵਸਤੂਆਂ ਦੇ ਉਦਯੋਗ ਵਿੱਚ ਕੰਮ ਕਰਨ ਦੇ ਪ੍ਰਦਰਸ਼ਿਤ ਇਤਿਹਾਸ ਦੇ ਨਾਲ ਖਰੀਦ ਨਿਰਦੇਸ਼ਕ।"',
  tumul: 'ਤੁਮੂਲ ਸਿੰਘ',
  tumul_post: 'ਡਾਇਰੈਕਟਰ (HR)',
  tumul_desc:
    'ਨਤੀਜਾ ਮੁਖੀ ਪੇਸ਼ੇਵਰ, ਮਾਹਰ ਐਚਆਰ ਕਰਮਚਾਰੀ ਅਤੇ 12 ਸਾਲਾਂ ਤੋਂ ਵੱਧ ਦੇ ਤਜ਼ਰਬੇ ਵਾਲੇ ਪ੍ਰੇਰਕ ਨੇਤਾ।"',
  amit: 'ਅਮਿਤ ਅਗਰਵਾਲ',
  amit_post: 'ਮੁੱਖ ਡਾਟਾ ਵਿਗਿਆਨੀ',
  amit_desc:
    "ਪੈਮਾਨੇ 'ਤੇ ਅਸਲ-ਸੰਸਾਰ ਦੀਆਂ ਸਮੱਸਿਆਵਾਂ ਨੂੰ ਹੱਲ ਕਰਨ ਲਈ ਬਹੁਤ ਜਨੂੰਨ ਵਾਲਾ ਇੱਕ ਤਜਰਬੇਕਾਰ ਨੇਤਾ।",
  prachi: 'ਪ੍ਰਾਚੀ ਮਦਾਨ',
  prachi_post: 'ਡਿਜੀਟਲ ਮਾਰਕੀਟਿੰਗ ਅਤੇ ਗਾਹਕ ਸਫਲਤਾ ਦੀ ਅਗਵਾਈ ਕਰੋ',
  prachi_desc:
    'ਡਿਜੀਟਲ ਮਾਰਕੀਟਿੰਗ ਅਤੇ ਪ੍ਰਚੂਨ ਰਣਨੀਤੀ ਵਿੱਚ ਕੰਮ ਦੇ 14 ਸਾਲਤਜ਼ਰਬੇ ਵਾਲਾ ਇੱਕ ਯੋਗ ਨੇਤਾ। ”',
  rupish: 'ਰੂਪਿਸ਼ ਸਾਲਦੀ',
  rupish_post: 'ਮੁੱਖ- ਵਿਕਰੀ ਅਤੇ ਖਰੀਦ',
  rupish_desc:
    'ਸੇਲਜ਼ ਹੈੱਡ ਦੀ ਭੂਮਿਕਾ ਵਿੱਚ, ਉਹ ਮਿਸ਼ਨ "ਸਿਹਤਮੰਦ ਪਸ਼ੂ ਖੁਸ਼ਹਾਲ ਕਿਸਾਨ" ਦੀ ਸਫ਼ਲਤਾ ਲਈ ਮੇਰਾਪਸ਼ੂ360 ਉਤਪਾਦਾਂ ਲਈ ਇੱਕ ਮਜ਼ਬੂਤ ​​​​ਡਿਸਟ੍ਰੀਬਿਊਸ਼ਨ ਨੈੱਟਵਰਕ ਬਣਾ ਰਹੇ ਹਨ।',
  we_are_hiring: 'ਅਸੀਂ ਹਮੇਸ਼ਾ ਭਰਤੀ ਰਹਾਂਗੇ।',
  see_job_openings: 'ਸਾਰੀਆਂ ਮੁਲਾਕਾਤਾਂ ਦੇਖੋ',
  we_are_growing:
    'ਅਸੀਂ ਹਮੇਸ਼ਾ ਨਵੇਂ ਅਤੇ ਅਭਿਲਾਸ਼ੀ ਪ੍ਰਤਿਭਾਵਾਂ ਨਾਲ ਗੱਲਬਾਤ ਕਰਨ ਲਈ ਉਤਸੁਕ ਰਹਿੰਦੇ ਹਾਂ। ਜੇਕਰ ਤੁਸੀਂ ਸਾਡੇ ਮਿਸ਼ਨ ਵਿੱਚ ਯੋਗਦਾਨ ਪਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ ਅਤੇ ਸਾਡੇ ਡੇਅਰੀ ਕਿਸਾਨਾਂ ਲਈ ਇੱਕ ਬਿਹਤਰ ਈਕੋਸਿਸਟਮ ਬਣਾਉਣ ਲਈ ਕੰਮ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ ਤਾਂ ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ।',
  join_us: 'ਸਾਡੇ ਨਾਲ ਸ਼ਾਮਲ',
  mail_us: "'ਤੇ ਮੇਲ ਕਰੋ",
  browse_bhains: '75+ ਪੈਮਾਨੈ ਤੇ ਜਾਂਚ ਕੀਤੀ ਗਈ <a>ਮੁਰਾਹ ਮੱਝ</a> ਵੇਖੋ',
  new_lac: 'ਨਵੀਂ ਪਤਨੀ',
  milk_yeild: '8-16 ਲੀਟਰ ਦੁੱਧ ਦੀ ਪੈਦਾਵਾਰ',
  checked: 'ਤਜਰਬੇਕਾਰ ਪਸ਼ੂਆਂ ਦੇ ਡਾਕਟਰਾਂ ਦੁਆਰਾ ਪ੍ਰਮਾਣਿਤ',
  browse_all_bhains: 'ਸਾਰੀਆਂ ਮੁਰਾਹ ਮੱਝਾਂ ਨੂੰ ਵੇਖੋ',
  apply_filter: 'ਫਿਲਟਰ ਲਾਗੂ ਕਰੋ',
  clear_filter: 'ਫਿਲਟਰ ਹਟਾਓ',
  milk: 'ਦੁੱਧ',
  price: 'ਕੀਮਤ',
  byayi: 'ਪਤਨੀ',
  hub: 'ਹੱਬ',
  calving: 'ਬਾਈਟ',
  litre: 'ਲੀਟਰ',
  more_than_20_litre: '20 ਲੀਟਰ ਤੋਂ ਵੱਧ',
  day: 'ਦਿਨ',
  all_cattle: 'ਸਾਰੇ ਜਾਨਵਰ',
  syc_title: 'ਸਿਰਫ 2 ਦਿਨਾਂ ਵਿੱਚ ਆਪਣਾ ਪਸ਼ੂ ਵੇਚੋ',
  sycfeat1: 'ਤੁਰੰਤ ਕੀਮਤ ਜਾਣੋ',
  sycfeat2: 'ਮੁਫਤ ਗੁਣਵੱਤਾ  ਮੁਲਾਂਕਣ',
  sycfeat3: ' ਮੁਫ਼ਤ ਪਸ਼ੂ ਪਿਕਅੱਪ',
  syc_step_title: '2 ਆਸਾਨ ਕਦਮਾਂ ਨਾਲ ਆਪਣਾ ਪਸ਼ੂ ਵੇਚੋ',
  cattle_price: '2 ਆਸਾਨ ਕਦਮਾਂ ਨਾਲ ਆਪਣਾ ਪਸ਼ੂ ਵੇਚੋ',
  syc_step_one: 'ਆਮ ਪਸ਼ੂ ਜਾਣਕਾਰੀ ਦਰਜ ਕਰੋ',
  syc_step_two: 'ਅਨੁਮਾਨਿਤ ਕੀਮਤ ਜਾਣੋ',
  syc_basic_detail: 'ਆਮ ਜਾਣਕਾਰੀ',
  syc_type: 'ਪਸ਼ੂ ਦੀ ਬਣਤਰ',
  syc_breed: 'ਪਸ਼ੂ ਦੀ ਨਸਲ',
  syc_breed_buffalo: 'ਮੱਝ',
  syc_byat: 'ਬਾਈਟ ਨੰਬਰ',
  syc_milk: 'ਤਿਆਰ ਦੁੱਧ',
  syc_byayi: 'ਕਿੰਨੇ ਦਿਨ',
  syc_child: 'ਵੱਛੇ ਦਾ ਲਿੰਗr',
  syc_height: 'ਪਸ਼ੂ  ਦੀ ਉਚਾਈ',
  syc_pincode: 'ਪਿੰਨ ਕੋਡ',
  katda: 'ਨਰ',
  katdi: 'ਮਾਦਾ',
  none: 'ਕੋਈ ਨਹੀਂ',
  next: 'ਅਗਲਾ',
  get_estimated_price: 'ਅਨੁਮਾਨਿਤ ਕੀਮਤ ਜਾਣੋ',
  estimated_price: '"ਅਨੁਮਾਨਿਤ ਕੀਮਤ',
  enter_mobile: 'ਕਿਰਪਾ ਕਰਕੇ ਮੋਬਾਈਲ-ਨੰਬਰ ਦਰਜ ਕਰੋ',
  other_comments: 'ਹੋਰ ਜਵਾਬ',
  inch: 'ਇੰਚ',
  contact_us_sub: 'ਸੰਪਰਕ ਕਰੋ',
  contact_us_title: 'ਅਸੀਂ ਤੁਹਾਡੀ ਮਦਦ ਕਰਨ ਲਈ ਇੱਥੇ ਹਾਂ',
  get_query_solved: "ਇੱਕ ਕਾਲ 'ਤੇ ਆਪਣੀਆਂ ਸਾਰੀਆਂ ਸਮੱਸਿਆਵਾਂ ਦਾ ਹੱਲ ਪ੍ਰਾਪਤ ਕਰੋ",
  feel_free: 'ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰਨ ਲਈ ਬੇਝਿਜਕ ਮਹਿਸੂਸ ਕਰੋ',
  reach_out: 'ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ',
  view_on_map: "ਗੂਗਲ ਮੈਪ 'ਤੇ ਦੇਖੋ",
  email: 'ਈ - ਮੇਲ',
  mobile_number: 'ਮੋਬਾਈਲ ਨੰਬਰ',
  subject: 'ਵਿਸ਼ਾ',
  message: 'ਸੁਨੇਹਾ',
  submit_form: 'ਫਾਰਮ ਜਮ੍ਹਾਂ ਕਰੋ',
  submitting: 'ਕ੍ਰਿਪਾ ਕਰਕੇ ਉਡੀਕ ਕਰੋ',
  corp_office: 'ਸਾਡੇ ਕਾਰਪੋਰੇਟ ਦਫ਼ਤਰ',
  mp_pvt_ltd: 'Merapashu360 ਪ੍ਰਾਈਵੇਟ ਲਿਮਿਟੇਡ',
  dosage:"ਕਿਵੇਂ ਖੁਆਉਣਾ ਹੈ",
  composition:"ਕਿਵੇਂ ਬਣਾਉਣਾ ਹੈ",
  benefits:"ਖੁਰਾਕ ਦੇ ਲਾਭ",
  office_add:
    'ਬਿਲਡਿੰਗ ਨੰਬਰ 104, ਫੇਜ਼ V, ਉਦਯੋਗ ਵਿਹਾਰ, ਸੈਕਟਰ 18, ਗੁਰੂਗ੍ਰਾਮ, ਸ਼ਾਹਪੁਰ, ਹਰਿਆਣਾ 122022',
  openfrom: 'ਖੁੱਲਣ ਦਾ ਸਮਾਂ: ਸਵੇਰੇ 9:00 ਵਜੇ ਤੋਂ ਸ਼ਾਮ 7:00 ਵਜੇ ਤੱਕ',
  call: 'ਕਾਲ ਕਰੋ- 9971182446',
  see_all_bhains: 'ਸਾਰੀਆਂ ਮੱਝਾਂ ਦੇਖੋ',
  hero_subtitle: "<span>75+ ਪੈਰਾਮੀਟਰਾਂ</span> 'ਤੇ ਟੈਸਟ ਕੀਤਾ ਗਿਆ।",
  hero_title: 'ਘਰ ਬੈਠੇ <a1>ਉੱਤਮ ਮੁਰਾਹ ਮੱਝ</a1> <br/> ਲਵੋ ਵਾਜਬ ਕੀਮਤ ਵਿੱਚ',
  get_100_app_download: "ਪਹਿਲੇ ਐਪ ਆਰਡਰ 'ਤੇ ₹100 ਦੀ ਛੂਟ",
  read_more: 'ਹੋਰ ਪੜ੍ਹੋ',
  partnership_page : "ਪਾਰਟਨਰਸ਼ਿਪ",
  impact_heading:"ਪ੍ਰਭਾਵ",
  impact_subheading:"ਮੇਰਾਪਾਸ਼ੁ360 ਪ੍ਰਭਾਵ",
  impact_text1:"15% ਤੱਕ",
  impact_sub1:"ਕਿਸਾਨਾਂ ਦੀ ਆਮਦਨ ਵਿੱਚ <br/> ਵਾਧੇ ਦਾ ਅਨੁਮਾਨ ਹੈ",
  impact_text2:"10 ਲੱਖ+",
  impact_sub2:"ਕਿਸਾਨਾਂ ਨਾਲ <br/> ਜੁੜਿਆ ਹੈ",
  impact_text3:"30,000+",
  impact_sub3:"ਪਿੰਡ ਤੱਕ<br/> ਪਹੁੰਚ",
  impact_text4:"20%",
  impact_sub4:"ਔਰਤ<br/>ਗਾਹਕ",
  faq_list:{
    "ਮੈਂ ਆਪਣਾ ਪਸ਼ੂ ਕਿਵੇਂ ਵੇਚਾਂ?":[
      "ਫੋਨ ਵਿੱਚ ਮੇਰਾਪਸ਼ੂ 360 ਐਪ ਖੋਲ੍ਹੋ, ਹੋਮ ਸਕ੍ਰੀਨ 'ਤੇ ਤੀਜੇ ਵਿਕਲਪ - ਪਸ਼ੂ ਮੁੱਲ 'ਤੇ ਕਲਿੱਕ ਕਰੋ।",
      "ਪਸ਼ੂ ਦੀ ਜਾਣਕਾਰੀ ਭਰਨਾ ਸ਼ੁਰੂ ਕਰੋ ਅਤੇ ਫਿਰ ਪਸ਼ੂ ਦੇ ਪਿਛਲੇ ਪਾਸੇ ਦੀ ਸਪਸ਼ਟ ਫੋਟੋ ਪਾਯੋ।",
      "ਯਾਦ ਰੱਖੋ - ਪਸ਼ੂ ਦੀ ਜਾਣਕਾਰੀ ਅਤੇ ਫੋਟੋ ਜਿੰਨੀ ਸਪਸ਼ਟ ਹੋਵੇਗੀ, ਖਰੀਦ ਦੀ ਸੰਭਾਵਨਾ ਓਨੀ ਹੀ ਬੇਹਤਰ ਹੋਵੇਗੀ।",
      "ਤੁਸੀਂ ਆਪਣੇ ਪਸ਼ੂ ਦੀ ਅਨੁਮਾਨਿਤ ਕੀਮਤ ਦਰਜ ਕਰੋ ਅਤੇ ਬਜ਼ਾਰ ਦੇ ਅਨੁਸਾਰ ਪਸ਼ੂ ਦੀ ਸਭ ਤੋਂ ਵਧੀਆ ਕੀਮਤ ਜਾਣੋ।"
    ],
    "ਜੇਕਰ ਮੈਂ ਆਪਣੀ ਮੱਝ Merapashu360 ਨੂੰ ਵੇਚਦਾ ਹਾਂ ਤਾਂ ਮੈਨੂੰ ਭੁਗਤਾਨ ਕਿਵੇਂ ਮਿਲੇਗਾ?":[
      "ਵੇਚਣ ਲਈ ਬੇਨਤੀ ਕਰਨ ਤੋਂ ਬਾਦ, ਜੇਕਰ ਤੁਹਾਡੀ ਮੱਝ ਸਾਡੇ ਯੋਗਤਾ ਦੇ ਮਾਪਦੰਡ ਦੇ ਅੰਦਰ ਆਉਂਦੀ ਹੈ, ਫਿਰ ਸਾਡੀ ਤਜਰਬੇਦਾਰ ਟੀਮ ਤੁਹਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੇਗੀ ਅਤੇ ਮੱਝਾਂ ਦੀ ਗੁਣਵੱਤਾ ਦੀ ਜਾਂਚ ਕਰਨ ਲਈ ਤੁਹਾਡੇ ਘਰ ਆਵੇਗੀ।",
      "ਜੇਕਰ ਮੱਝਾਂ ਗੁਣਵੱਤਾ ਜਾਂਚ ਦੀ ਪ੍ਰਕਿਰਿਆ ਤੇ ਪਾਸ ਹੁੰਦੀਆਂ ਹਨ, ਤਾਂ ਭੁਗਤਾਨ ਆਨਲਾਈਨ (ਨੈੱਟ ਬੈਂਕਿੰਗ) ਰਾਹੀਂ ਕੀਤਾ ਜਾਵੇਗਾ।",
      "ਭੁਗਤਾਨ 24 ਘੰਟਿਆਂ ਦੇ ਅੰਦਰ ਤੁਹਾਡੇ ਬੈਂਕ ਖਾਤੇ ਵਿੱਚ ਦਿਖਾਈ ਦੇਵੇਗਾ।"
    ],
    "ਮੈਂ ਮੇਰਾਪਸ਼ੂ360 ਤੋਂ ਮੱਝਾਂ ਕਿਵੇਂ ਖਰੀਦ ਸਕਦਾ ਹਾਂ?":[
      "ਮੱਝ ਖਰੀਦਣ ਲਈ, ਮੈਂ ਮੇਰਾਪਸ਼ੂ 360 ਐਪ ਖੋਲ੍ਹੋ, ਹੋਮ ਸਕ੍ਰੀਨ 'ਤੇ ਜਾਓ ਅਤੇ ਪਸ਼ੂ ਖਰੀਦੋ ਵਿਕਲਪ ਨੂੰ ਚੁਣੋ।",
      "ਮੌਜੂਦਾ ਵਧੀਆ ਕੁਆਲਿਟੀ ਦੀਆਂ ਬਹੁਤ ਸਾਰੀਆਂ ਮੁਰਾਹ ਮੱਝਾਂ ਬਾਰੇ ਜਾਣਕਾਰੀ - ਬਯਾਤ, ਤਿਆਰ ਦੁੱਧ, ਕੀਮਤ, ਲਾਈਵ ਵੀਡੀਓ, ਦੇਖੋ ਫੋਟੋ ਆਪਣੀ ਪਸੰਦ ਦੀ ਮੁਰਾਹ ਚੁਣੋ।",
      "ਸਾਡੇ ਕੋਲ 40+ ਵਧੀਆ ਕੁਆਲਿਟੀ ਦੇ ਟੈਸਟ ਕੀਤੇ ਮੁਰਾਹ ਮੱਝਾਂ ਦੀਆਂ ਦੀ ਇੱਕ ਵਿਸ਼ਾਲ ਸ਼੍ਰੇਣੀ ਹੈ।",
      "ਤੁਸੀਂ ਪਸ਼ੂ ਨੂੰ ਦੇਖਣ ਲਈ ਐਪ ਰਾਹੀਂ ਫਾਰਮ ਵਿਜ਼ਿਟ ਬੁੱਕ ਕਰ ਸਕਦੇ ਹੋ ਜਾਂ ਤੁਸੀਂ ਵੀਡੀਓ ਕਾਲ ਰਾਹੀਂ ਪਸ਼ੂ ਨੂੰ ਦੇਖ ਸਕਦੇ ਹੋ। ਹੋਰ ਵੇਰਵਿਆਂ ਲਈ ਤੁਸੀਂ ਸਾਡੇ ਪ੍ਰਤੀਨਿਧੀ ਨੂੰ- 9971182446 'ਤੇ ਸੰਪਰਕ ਕਰ ਸਕਦੇ ਹੋ।",
      "ਜਾਂਚ ਅਤੇ ਦੇਖਣ ਦੀ ਪ੍ਰਕਿਰਿਆ ਪੂਰੀ ਹੋਣ ਤੋਂ ਬਾਅਦ, ਜੇਕਰ ਤੁਸੀਂ ਸਹਿਮਤ ਹੋ, ਤਾਂ 24 ਘੰਟਿਆਂ ਦੇ ਅੰਦਰ ਤੁਹਾਡੇ ਪਸ਼ੂ ਦੀ ਮੁਫਤ ਡਿਲੀਵਰੀ ਕੀਤੀ ਜਾਵੇਗੀ (50 ਕਿਲੋਮੀਟਰ ਦੇ ਅੰਦਰ), 50 ਕਿਲੋਮੀਟਰ ਤੋਂ ਵੱਧ ਦੂਰੀ ਲਈ ਡਿਲੀਵਰੀ ਖਰਚਿਆਂ ਵਿੱਚ ਤੁਹਾਡੀ ਮਦਦ ਕਰਨ ਲਈ ਸਾਡਾ ਪ੍ਰਤੀਨਿਧੀ ਮੌਜੂਦ ਹੋਵੇਗਾ",
      "ਮੱਝ ਦੀ ਚੋਣ ਤੋਂ ਬਾਅਦ, 10 ਹਜ਼ਾਰ ਰੁਪਏ ਡਲਿਵਰੀ ਤੋਂ ਪਹਿਲਾਂ ਅਦਾ ਕਰਨੇ ਪੈਣਗੇ, ਡਲਿਵਰੀ ਦੇ ਸਮੇਂ ਬਾਕੀ ਭੁਗਤਾਨ ਨਕਦ ਕਰ ਸਕਦੇ ਹੋ I ਜੋ ਗਾਹਕ ਹੱਬ ਤੋਂ 50 ਕਿਲੋਮੀਟਰ ਦੇ ਅੰਦਰ ਸਥਿਤ ਹਨ, ਉਨ੍ਹਾਂ ਨੂੰ ਡਿਲੀਵਰੀ ਤੋਂ ਪਹਿਲਾਂ ਪੂਰਾ ਭੁਗਤਾਨ ਕਰਨਾ ਹੋਵੇਗਾ।"
    ],
    "ਮੇਰਾਪਸ਼ੂ360 ਆਹਾਰ ਕਦੋਂ ਡਿਲੀਵਰ ਕੀਤਾ ਜਾਵੇਗਾ?":[
      "ਤੁਹਾਡਾ ਪਸ਼ੂ ਆਹਾਰ 24 ਘੰਟਿਆਂ ਦੇ ਅੰਦਰ ਤੁਹਾਡੇ ਘਰ ਪਹੁੰਚਾਇਆ ਜਾਵੇਗਾ। ਜੇਕਰ ਆਰਡਰ ਦੁਪਹਿਰ 3 ਵਜੇ ਤੋਂ ਪਹਿਲਾਂ ਦਿੱਤਾ ਜਾਂਦਾ ਹੈ ਨਹੀਂ ਤਾਂ ਡਿਲੀਵਰੀ 48 ਘੰਟਿਆਂ ਦੇ ਅੰਦਰ ਹੋਵੇਗੀ।",
      "ਡਿਲੀਵਰੀ ਟੀਮ, ਡਿਲੀਵਰੀ ਵਾਲੇ ਦਿਨ ਆਰਡਰ ਦੀ ਪੁਸ਼ਟੀ ਕਰਨ ਲਈ ਤੁਹਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੇਗੀ।",
      "ਕਿਰਪਾ ਕਰਕੇ ਡਿਲੀਵਰੀ ਵਾਲੇ ਦਿਨ ਭੁਗਤਾਨ ਦੀ ਰਕਮ/UPI ਤਿਆਰ ਰੱਖੋ ਜਾਂ ਰਿਸ਼ਤੇਦਾਰਾਂ ਨੂੰ ਸੌਂਪ ਦੇਊ",
      "ਕਿਰਪਾ ਕਰਕੇ ਯਕੀਨੀ ਬਣਾਓ ਕਿ ਪਰਿਵਾਰ ਦਾ ਕੋਈ ਮੈਂਬਰ ਘਰ ਵਿੱਚ ਮੌਜੂਦ ਹੌਵੇ  ਤਾਂ ਜੋ ਅਸੀਂ ਬਿਨਾਂ ਕਿਸੇ ਅਸੁਵਿਧਾ ਦੇ ਤੁਹਾਡੇ ਆਰਡਰ ਨੂੰ ਡਿਲੀਵਰ ਕਰ ਸਕੀਏ।"
    ],
    "ਮੱਝ ਖਰੀਦਣ ਤੋਂ ਬਾਦ ਮੇਰਾਪਸ਼ੂ360   ਵੱਲੋਂ ਕਿਹੜੀਆਂ ਸਹੂਲਤਾਂ ਦਿੱਤੀਆਂ ਜਾਣਗੀਆਂ?":[
      "ਸਾਡੇ ਤੋਂ ਪਸ਼ੂ ਖਰੀਦਣ ਦੇ ਬਹੁਤ ਸਾਰੇ ਫਾਇਦੇ ਹਨ:",
      "• ਮੁਫਤ ਹੋਮ ਡਿਲੀਵਰੀ ਸਹੂਲਤ (ਫਾਰਮ ਤੋਂ 50 ਕਿਲੋਮੀਟਰ ਦੀ ਦੂਰੀ ਦੇ ਅੰਦਰ)",
      "• ਮੇਰਾਪਸ਼ੂ360 ਦੇ ਤਜਰਬੇਦਾਰ ਟੀਮ ਦੁਆਰਾ ਪੂਰੀ ਤਰ੍ਹਾਂ ਮੁਲਾਂਕਣ ਤੋਂ ਬਾਦ ਮੱਝਾਂਨੂੰ ਫਾਰਮ ਵਿੱਚ ਲਿਆਂਦਾ ਜਾਂਦਾ ਹੈ ਅਤੇ ਟੀਮ ਪਹਿਲਾਂ ਮੱਝਾਂ ਦੀ ਗੁਣਵੱਤਾ ਦੀ ਜਾਂਚ ਕਰਦੀ ਹੈ, ਮੱਝਾਂ ਨੂੰ 75+  ਪੈਮਾਨੇ 'ਤੇ ਮਾਪਦੀ ਹੈ ਅਤੇ ਹਰ ਪੈਰਾਮੀਟਰ ਨੂੰ ਪਾਸ ਕਰਨ ਤੋਂ ਬਾਦ, ਪਸ਼ੂ ਤੁਹਾਡੇ ਕੋਲ ਪਹੁੰਚਾ ਦਿੱਤਾ ਜਾਂਦਾ ਹੈ।",
      "• ਪਸ਼ੂਆਂ ਦੀ ਸਮੱਸਿਆ ਨਾਲ ਸਬੰਧਤ ਕਿਸੇ ਵੀ ਜਾਣਕਾਰੀ ਲਈ ਵੈਟ ਦੁਆਰਾ ਮੁਫਤ ਸਲਾਹ ਦੀ ਸਹੂਲਤ, ਸਾਡੇ ਪ੍ਰਤੀਨਿਧੀ ਨਾਲ ਸੰਪਰਕ ਕਰੋ- 9971182446",
      "• ਤੁਸੀਂ ਫਾਰਮ ਦਾ ਦੌਰਾ ਕਰ ਸਕਦੇ ਹੋ ਅਤੇ ਸਾਡੀਆਂ ਮੱਝਾਂ ਦਾ ਲਾਈਵ ਦੁੱਧ ਅਤੇ ਤਿਆਰ ਦੁੱਧ ਦੇਖ ਸਕਦੇ ਹੋ।"
    ],
    "ਕੀ ਮੈਂ ਮੇਰਾਪਸ਼ੂ360 'ਤੇ ਉਪਲਬਧ ਮੱਝਾਂ ਦੇ ਵਿਕਲਪਾਂ ਨੂੰ ਦੇਖਣ ਲਈ ਫਾਰਮ ਦੇ ਦੌਰੇ ਨੂੰ ਤਹਿ ਕਰ ਸਕਦਾ ਹਾਂ?":[
      "ਮੈਂ ਮੇਰਾਪਸ਼ੂ360  ਕੋਲ ਮੌਜੂਦਾ ਫਾਰਮ - ਗੁੜਗਾਉਂ (ਹਰਿਆਣਾ), ਤੇ ਜਾ ਸਕਦੇ ਹੋ।",
      "ਆਪਣੇ ਨਿਰਧਾਰਿਤ ਸਮੇਂ 'ਤੇ ਸਾਡੇ ਪ੍ਰਤੀਨਿਧੀ ਨਾਲ ਸੰਪਰਕ ਕਰਕੇ - 9971182446 ਘਰ ਬੈਠੇ ਤੁਸੀਂ ਪਸ਼ੂ ਅਤੇ ਉਸਦੀ ਲਾਈਵ ਦੁੱਧ ਦੇਣ ਦੀ ਵੀਡੀਓ ਦੇਖ ਸਕਦੇ ਹੋ।",
      "ਐਪ ਅਤੇ ਸਾਡੇ ਪ੍ਰਤੀਨਿਧੀ ਵਿੱਚ ਖਰੀਦੋ ਵਿਕਲਪ 'ਤੇ ਕਲਿੱਕ ਕਰਕੇ ਮਨਪਸੰਦ ਮੱਝ ਦੀ ਚੋਣ ਕਰੋ ਸੰਪਰਕ ਕਰੋ ਅਤੇ ਉਸਦਾ ਵੇਰਵਾ ਦਿਓ, ਤੁਹਾਡੀ ਕਾਲ ਫਾਰਮ ਨਾਲ ਕਿਤੀ ਜਾਵੇਗੀ ਅਤੇ ਪਸ਼ੂ ਨੂੰ ਵੀਡੀਓ ਕਾਲਿੰਗ ਰਾਹੀਂ ਲਾਈਵ ਦਿਖਾਇਆ ਜਾਵੇਗਾ।",
      "ਤੁਸੀਂ ਐਪ 'ਤੇ ਆਪਣੇ ਕਾਰਜਕ੍ਰਮ ਦੇ ਅਨੁਸਾਰ ਫਾਰਮ ਵਿਜ਼ਿਟ ਨੂੰ ਆਨਲਾਈਨ ਵੀ ਬੁੱਕ ਕਰ ਸਕਦੇ ਹੋ।"
    ]
  },
  our_investors: 'OUR INVESTORS',
  about_our_investors: "Along the journey, we have been lucky to get encouragement & support from credible folks from the industry. Founders, CxOs, & Principal & Partners of Consult, VCs, PEs, & Big-4. Happily, 25+ college-mates of our founders (IIT Madras) are part of cap-table, while some prominent others are ex-colleagues & clients.",
  investor_footer: "Want to know more? We can add you to our quarterly mailing list for biz update.",
  investors_include: "Our investors include"
};

export default _paTranslations;

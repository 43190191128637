const _enTranslations = {

  nav_language: "Language",
  nav_subtext: "Best Choice of 27k+ Farmers",
  nav_call: "Call us between 8 AM - 7 PM",
  nav_search_placeholder: "Search Bhains, Binola Khal, Silage",
  nav_get: "₹100 off on 1st order",
  nav_download_app: "Download App",
  lakh_downloads: "Lakh+ Downloads",
  we_offer_sub: "WHAT WE OFFER",
  we_offer_title: "WHY BUY FROM US",
  downloads: "Downloads",
  download_mob:"Download & get ₹100 off\non first order",
  rating: "Rating",
  rating_on_google_playStore: 'Rating on Google Playstore',
  we_offer_one_title: "Fast Home Delivery",
  we_offer_one_desc:
    "Delivery within 50 kilometres of our nearby centres within 24 hours.",
  we_offer_two_title: "Quality Checks",
  we_offer_two_desc:
    "Best quality products maintained by experienced teams with regular quality checks",
  we_offer_three_title: "Quality Product",
  we_offer_three_desc:
    "Premium quality cattle feed delivered fresh to your doorstep directly from the factory.",
  we_offer_four_title: "Cash on Delivery",
  we_offer_four_desc:
    "Pay via cash after a hassle free delivery of your product at your doorstep.",
  buy_cattle_feed_sub: "BUY CATTLE FEED",

  buy_cattle_feed_title:
    "<a>PREMIUM QUALITY FEED</a> FOR YOUR<br/>PASHU STARTING<a> ₹260</a>",


  binola_khal: "Binola Khal",
  explore_categories:"Explore Categories",
  explore_categories_one:"Explore Categories",
  combo_offer: "Combo Offer",
  combo_sub:"Combos for max savings",
  khal: 'Khal',
  binola_khal_sub: 'Best Source of Protein & Fat',
  sarso_khal: 'Sarso Khal',
  sarso_khal_sub: 'High Protein Feed',
  goli_khal: 'Goli Khal',
  goli_khal_sub: 'Complete Balanced Feed',
  mineral_mix: 'Supplements',
  mineral_mix_sub: 'Maintain Reproductive Health & Growth',
  silage: 'Silage',
  silage_sub: 'Highly Palatable & Nutritive',
  traditional_khal: 'Traditional Feed',
  traditional_khal_sub: "Best Source of Fiber & Energy",
  browse_all_cattle_feed: 'Browse all Cattle Feed',
  vet_sub: 'FACING ANY PROBLEMS?',
  vet_title: 'GET SOLUTION FROM OUR <span>EXPERIENCED VET DOCTORS<span>',
  vet_oncall: '24x7 Available @ Call',
  vet_atHome: 'Home Consultancy Available',
  download_app: 'Download the MeraPashu360 App',
  contact_us: 'Contact Us',
  videos: 'Videos',
  asked_answered: 'YOU ASKED WE ANSWERED',
  go_to_youtube: 'GO TO OUR YOUTUBE CHANNEL',
  know: 'KNOW',
  customers_say: 'WHAT <span>OUR CUSTOMERS</span> <br/> HAVE TO SAY',
  who_are_we: 'WHO ARE WE',
  know_us: 'KNOW ABOUT US',
  about_us_desc:
    "At MeraPashu360, we set out to build a technology-cum-physical platform to make the lives of dairy farmers better, by providing every small dairy farmer with access to good quality cattle, nutrition, veterinary services, and health advisory and make the dairy industry organized.",
  know_more: "Know More",
  kisan_served: "Kisan Served",
  pin_code_served: "Orders delivered",
  katte_sold: "Tons of feed",
  buffalo_sold: "Buffaloes & cows sold",
  app_downloads: "App Downloads",
  merapashu_app: "MERAPASHU360 APP",
  download_app_title:
    "Download <a>MeraPashu360 App</a> &<br/> get <a> flat ₹100 off </a> on first order",
  browse_cattle: "<a>Browse Murrah Bufallo tested on 75+ quality parameters</a>",
  buy_feed: "<a>Buy Santulit Aahar</a>",
  fat: "FAT",
  sell_cattle: "<a>Check Cattle Price</a>",
  check_cattle_price: "Check Cattle Price",
  buy_cattle_sub:"Murrah buffalo, HF Cow, Sahiwal",
  check_cattle_price_sub:"Check market price of your cattle",
  home: "Home",
  get_vet: "<a>Get Vet Consultation</a>",
  download_now: "Download Now",
  company: "Company",
  about_us: "About Us",
  career: "Careers",
  blog: "Blog",
  meet_with_us: "Meet with Us",
  faq: "FAQ",
  explore: "Explore",
  buy_cattle_feed: "Buy Cattle Feed",
  buy_cattle: "Explore Cattle",
  sell_your_cattle: "Check Cattle Price",
  hub_loc: "Hub Location",
  gurgaon: "Gurgaon",
  jind: "Jind",
  matan: "Matan",
  ganganagar: "Sriganganagar",
  sirsa: "Sirsa",
  delivering: "Delivering Feed Across",
  delhi: "Delhi",
  haryana: "Haryana",
  punjab: "Punjab",
  rajasthan: "Rajasthan",
  up: "Uttar Pradesh",
  privacy_policy: "Privacy Policy",
  cancellation_policy: "Cancellation Policy",
  commerce_policy: "Commerce Policy",
  copyright: " Copyright {{year}} MeraPashu360 Private Limited",
  terms: "Terms of Use",
  media_coverage: "MEDIA COVERAGE",
  force_behind: "FORCE BEHIND MP360",
  meet_founding_team: "MEET THE FOUNDING TEAM",
  divyanshu: "Divyanshu Tambe",
  divyanshu_post: "Co-Founder & CBO",
  divyanshu_desc:
    "Helping dairy farmers run their business successfully @ MeraPashu360; Support start-ups solving for critical & large need",
  niket: "Niket Agrawal",
  niket_post: "Co-Founder & CEO",
  niket_desc:
    "On a journey to formalize and drive value creation in the large agriculture industry, ~ 30% of India's most marginalised section.",
  varun: "Varun Verma",
  varun_post: "Co-Founder & CTO",
  varun_desc:
    "Technology evangelist, Advisor and Mentor for Product Development and startups.",
  meet_leadership_team: "MEET THE LEADERSHIP TEAM",
  kanu: "Kanupriya Saldi",
  kanu_post: "Co-Founder & COO",
  kanu_desc:
    "An expert in purchasing and supply chain, staunch advocate of women's empowerment and financial independence, passionate about creating social impact.",
  tumul: "Tumul Singh",
  tumul_post: "Director H R",
  tumul_desc:
    "Result Oriented Professional, Expert Human Resource Personnel and motivational leader with more than 12 years of experience.",
  amit: "Amit Agarwal",
  amit_post: "Chief Data Scientist",
  amit_desc:
    "An experienced leader with immense passion to solve large scale real-world problems.",
  prachi: "Prachi Madan",
  prachi_post: "Lead Digital Marketing and Customer Success",
  prachi_desc:
    "An accomplished leader with ~14 years of work experience in Digital Marketing and retail strategy.",
  rupish: "Rupish Saldi",
  rupish_post: "Lead - Sales and Procurement",
  rupish_desc:
    'Leading field sales to build a robust distribution network of MP360 branded products for the actualisation of the moto "Swastha Pashu Samrudha Kisan"',

  we_are_hiring: "LOOKING FOR NEW TALENT",
  see_job_openings: "SEE JOB OPENINGS",
  we_are_growing:
    "Always keen to have a chat with new & hungry talent. If you want to contribute towards our mission and work on building a better ecosystem for our dairy farmers, reach out to us.",
  join_us: "Join Us",
  mail_us: "Mail Us at",
  browse_bhains:
    "BROWSE <a>CATTLE</a> TESTED ON 75+ QUALITY PARAMETERS",
  new_lac: "Newly lactating",
  milk_yeild: "Milk yield from 8-16 litre",
  checked: "Checked by professional vets",
  browse_all_bhains: "Browse all Cattle",
  apply_filter: "APPLY FILTERS",
  clear_filter: "CLEAR FILTERS",
  milk: "MILK",
  price: "PRICE",
  byayi: "BYAYI",
  hub: "HUB",
  calving: "CALVING",
  litre: "litre",
  more_than_20_litre: "More than 20 litres",
  day: "Day",
  all_cattle: "All Cattle",
  syc_title: "Sell Your Cattle in just 2 Days",
  sycfeat1: "Get Instant Price",
  sycfeat2: "Free Quality Evaluation",
  sycfeat3: "Pickup of Cattle",
  syc_step_title: "Sell your Cattle in 2 easy steps",
  cattle_price: "Cattle Price",
  syc_step_one: "Fill the basic details of Cattle",
  syc_step_two: "Get a estimated price",
  syc_basic_detail: "Fill in the basic details of cattle",
  syc_type: "Cattle Type",
  syc_breed: "Cattle Breed",
  syc_breed_buffalo: "Buffalo",
  syc_byat: "Lactation Number",
  syc_milk: "Current Milk Yeild",
  syc_byayi: "Days in Milk",
  syc_child: "Calf Gender",
  syc_height: "Cattle Height",
  syc_pincode: "Pincode",
  katda: "Katda",
  katdi: "Katdi",
  none: "None",
  next: "Next",
  address:"Village Dhumaspur, Near sector 63, Gurgaon Khewat sankhya 50, Khtoni sankhya 52, Mustil number 6, Killa number 3 Located at Gurgaon 122102",
  get_estimated_price: "Get Estimated Price",
  estimated_price: "Estimated Price of your cattle",
  enter_mobile: "Enter Mobile Number",
  other_comments: "Other Comments",
  inch: "inches",
  contact_us_sub: "Contact Us",
  contact_us_title: "We are here to help you 🤝",
  get_query_solved: "Get your queries solved at one call",
  feel_free: "Feel Free to contact us",
  reach_out: "Reach Out to us at",
  view_on_map: "VIEW ON GOOGLE MAPS",
  email: "Email",
  mobile_number: "Mobile No.",
  subject: "Subject",
  message: "Message",
  submit_form: "SUBMIT FORM",
  submitting: "SUBMITTING",
  dosage:"Dosage",
  composition:"Composition",
  benefits:"Benefits",
  Download_Button_sub: "Available for <span>FREE</span> on Google Play Store",
  corp_office: "Our Corporate Office",
  mp_pvt_ltd: "MeraPashu360 Pvt Limited",
  office_add:
    "1st Floor, AIHP Executive Center, Plot No 48, Institutional Area, Sector 32, Gurugram, Haryana 122001",
  openfrom: "Open from: 9:00 am - 7:00 pm",
  call: "Call: 9971182446",
  see_all_bhains: "See All Bhains",
  hero_subtitle: "<span>Cattle feed free home delivery </span> <br/> with in 24 hours ",
  hero_title:
    "Get <a2>balanced feed</a2>  & <a1>quality cattle</a1> at the best price.",
  get_100_app_download: "Flat ₹100 off on first app order",
  buy_bhains: "Buy cattle & cattle feed",
  purachase_cattle: "BUY CATTLE",
  hero_discount: "मुर्रा भैंस पर <span>₹25000</span> की छूट",
  partnership_page: "Partnership",
  partnership_page_desc:
    "Step into a world of shared growth and mutual success with MeraPashu360. We take pride in our journey of providing quality cattle feed to dairy farmers, and now, we're opening our doors to collaboration.",
  partnership_subheading: "PARTNERS IN GROWTH",
  partnership_heading: "Partnership",
  partnership_footer_text:"Let's build a future of progress together.",
  sectionOrder: ["partners_in_growth", "partnership_2"],
  subheading: {
    partners_in_growth: "Partners in Growth",
    partnership_2: "Partnership 2",
  },
  mainheading: {
    partners_in_growth: "MeraPashu360 x Dhyan Foundation",
    partnership_2: "MeraPashu360 x Partner 2",
  },
  content: {
    partners_in_growth: [
      "Formed in 2002 to share and spread the gyan of the original and pristine form of yog, Dhyan Foundation, today is a global phenomenon with centers all across the world where Sanatan Kriya (Patanjali Ashtang Yog in its original, undiluted form) sessions are conducted free of cost as per the ancient Guru-Shishya Parampara. There are thousands across the globe who have benefited physically, emotionally, mentally and spiritually from the practice of Sanatan Kriya.",
      "The Foundation is actively engaged in a host of charitable activities including free distribution of food at multiple places, saving wildlife and looking after injured cattles (Feed a Dog, Save a Cow, Help a Monkey, Befriend a Bird), running schools for the underprivileged, sponsoring higher education, generating employment, spreading awareness about environmental issues, to name a few. All this is done solely from the contributions of the volunteers and well-wishers.",
    ],
    partnership_2: [
      "Here, we embrace the power of collaboration with like-minded businesses, all sharing a common vision for positive impact. Explore the diverse range of possibilities waiting for you:",
      "Discover and engage with our featured partners, each bringing unique innovation and expertise to the collaborative table. Read uplifting success stories from businesses similar to yours, showcasing how fruitful collaborations have driven growth and success.",
    ],
  },
  buttonText: {
    partners_in_growth: "DONATE FEED",
    partnership_2: "CTA",
  },
  hasSubsection: {
    "partners_in_growth": true,
    "partnership_2": false
  },

  link: "LINK",
  read_more: "Read More",
  impact_heading:"IMPACT",
  impact_subheading:"IMPACT CREATED BY MERAPASHU360",
  impact_text1:"Upto 15%",
  impact_sub1:"Estimated Increase in Farmer Net- Income",
  impact_text2:"1 Mn+",
  impact_sub2:"Number of <br/> Farmers Reached",
  impact_text3:"30,000+",
  impact_sub3:"Villages <br/> Covered",
  impact_text4:"20%",
  impact_sub4:"Women <br/> Customer",
  faq_list: {
    "When will my feed be delivered to me?": [
      "Your cattle feed will be delivered within 24 hours if ordered before 3 PM otherwise within 48 hours.",
      "The delivery team will contact you for order confirmation on the day of delivery.",
      "Please keep the payment amount/UPI ready on the day of delivery or hand it over to the next of kin.",
      "Please ensure that a family member is present at home so we can deliver your order without any inconvenience."
    ],
    "How to order Feed from MeraPashu360 app?":[
      "Download MeraPashu360 app by visiting Play Store.",
      "Open the app and sign up by going to the home screen.",
      "Click on the category of Feed.",
      "Various options of cattle feed as per your choice like - Khal, Goli Khal, Traditional feed, Silage, Mineral Mixture. Click on any product - then select 1 or more than 1 bag.",
      "Then press the Go to Cart button below.",
      "During this time, use the remaining coupons or points to get the discount.",
      "Now enter your address by pressing the Select Address button below and click the Confirm button.",
      "Then press the Complete Order button and choose one of the payment options: Cash on Delivery, Online Payment.",
      "Press the Complete Order button again. This way your order will be entered successfully and you can see the total items and delivery date on the mobile screen."
    ],
    "Benefits of buying feed from MeraPashu360?":[
      "Fast and Free Home Delivery: Even 1 bag of cattle feed will reach your home within 24 hours.",
      "Cash on Delivery: Pay in cash after the order is delivered at home, without any hassles.",
      "Discount on UPI payments: 100 points discount on online payment of cattle feed through the app.",
      "An app with many options for cattle feed: online khal,goli khal, traditional cattle feed, silage, mineral mixture. You can see the categories and benefits on the app."
    ],
    "How many types of feed are available in the MeraPashu360 app?":[
      "More than 20 options of premium quality cattle feed rich in nutrients like khal, goli khal, traditional cattle feed, silage, mineral mixture are available on the app.",
      "Complete assurance of quality at reasonable prices.",
      "From lab-certified high-quality khal.",
      "Fresh factory made 100% pure cattle feed."
    ],
    "How can I buy Cow/Buffalo from MeraPashu360?":[
      "To buy Cow/Buffalo, open the MeraPashu360 app, go to the home screen and select the View cattle option.",
      "Information about various existing best quality cows/Murrah buffaloes - choose the Murrah of your choice by looking at their calving, finished milk, price, live video, photos. ",
      "We have multiple options of 40+ best quality tested Cow/Murrah Buffaloes available at the Hub.",
      "To see the cattles, you can book a farm visit through the app or view the cattles through video call. For other information you can contact our representative- 9971182446.",
      "After completion of the inspection and inspection process, if you agree, the cattle will be delivered at your home within 24 hours.      ",
      "After selection of cow/buffalo, payment of ₹ 10 thousand will have to be made before delivery and the remaining payment can be made in cash at the time of delivery. Any customer who is within 50 km from the hub. Will be located at a distance of more than 100 km, they will have to make full payment before delivery."
    ],
   
      "Benefits of feeding Merapashu360 balanced feed (Goli Khal) to cattles?":[
        "Santulit 8000: This is a balanced diet for the cattle. It provides huge amount of protein and fat to the cattle, which is necessary for the health of the milch cattle and more milk production.",
        "Santulit 11000 Energy Pellets: Energy-packed Balanced 11000 boosts cattle energy. It contains 2700 kcal and 70% TDN content. This new balanced 11000, which comes in a pack of 50 kg, contains cottonseed cake, mustard cake, maize, nutritious substances, toxin binder, mineral mixture, bran.",
        "Santulit 15000 High Energy Pellet: It contains many excellent nutrients which will provide high energy to your cattles i.e. plenty of energy. It contains 2900 Kcal and 75% TDN. The quantity is found. This will increase the milk production of the cattle. Their weight will increase and reproduction will become better and easier."
      ],
    "Can I schedule a farm visit to see the options of cow/buffalo available at MeraPashu360?":
      [
        "MeraPashu360 has a farm in Gurgaon (Haryana) which you can visit any time.",
        "By contacting our representative at your scheduled time - 9971182446, you can see the cattle and its live milking video, sitting at home",
        "Select your favourite cow/buffalo by clicking on the buy cattle option in the app, our representative will contact you and give the details. You will be connected to the farm and the cattle will be shown live by video calling.",
        "You can schedule farm visits online on the app as per your schedule",
      ],
  },
  our_investors: "OUR INVESTORS",
  about_our_investors:
    "Along the journey, we have been lucky to get encouragement & support from credible folks from the industry. Founders (27), CxOs (56), & Principal & Partners of Consult (30), VCs (5), PEs, & Big-4. Happily, 25+ college-mates of our founders (IIT Madras) are part of cap-table, while some prominent others are ex-colleagues & clients.",
  investor_footer:
    "Want to know more? We can add you to our quarterly mailing list for biz update.",
  investors_include: "Our investors include",
  celebrating: "CELEBRATING",
  featured: "FEATURED",
};

export default _enTranslations;



import { useEffect, useState } from 'react';
import call_icon from '../assets/call_white.svg';
import main_icon from '../assets/mail.svg';
import done from '../assets/done.svg';
import Button from '../components/Button/Button';
import { gql, useMutation } from '@apollo/client';
import HubLocations from '../components/HubLocations';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
declare global {
  interface Window {
    grecaptcha: any;
  }
}

function ContactUs() {
  let { t } = useTranslation();
  const SUBMIT_FORM = gql`
    mutation ContactUs($contactUsData: ContacUsInput!) {
      contactUs(contactUsData: $contactUsData) {
        success
        message
      }
    }
  `;
  let [
    submitForm,
    { data: submitFormRes, loading: submitFormLoading, error: submitFormError },
  ] = useMutation(SUBMIT_FORM);

  if (submitFormError) {
    console.log(submitFormError);
  }

  let handleContactUsSubmit = async (e: any) => {
    e.preventDefault();

    let data: any = {
      email: e.target.elements.email.value,
      mobile_no: e.target.elements.pNo.value,
      subject: e.target.elements.subject.value,
      message: e.target.elements.message.value,
    };


    // Retreive Captcha Token
    await window.grecaptcha.ready((_: any) => {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
          action: 'login',
        })
        .then((token: any) => {
          data['captchaToken'] = token;
          submitForm({
            variables: {
              contactUsData: data,
            },
          });
        });
    });
  };

  const [phoneNumber, setPhoneNumber] = useState('')

  const handlePhoneNumberChange = (e: any) => {
    const value = e.target.value
    const sanitizedValue = value.replace(/[a-zA-z]/g, '')
    setPhoneNumber(sanitizedValue)
  }

  const handleLoaded = (_: any) => {
    window.grecaptcha.ready((_: any) => {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
          action: 'login',
        })
        .then((token: any) => {});
    });
  };

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    script.addEventListener('load', handleLoaded);
    document.body.appendChild(script);
  }, []);
  return (
    <>
      <Helmet>
        <title>Contact Us | MeraPashu360</title>

        <meta
          name="description"
          content="Download the Merapashu360 app from the Play Store today or call our customer helpline number: 9971182446📲"
        />
        <link rel="canonical" href="https://merapashu360.com/contact-us/" />
      </Helmet>
      <div className="c_us__linearbg">
        <div className="c_us__container">
          <div className="c_us__heading">
            <p className="subheading">{t("contact_us_sub")}</p>
            <p className="heading">{t("contact_us_title")}</p>
          </div>

          <div className="c_us__ctas">
            <div className="c_us__cta">
              <img src={call_icon} alt="call icon" />
              <p className="white">{t("get_query_solved")}</p>
              <a href="tel:9971182446" className="heading white">
                9971182446
              </a>

              {/* <Button outline text="Call U s" color="white" /> */}
            </div>
            <div className="c_us__cta">
              <img src={main_icon} alt="mail icon" />
              <p>{t("feel_free")}</p>
              <a href="mailto:info@merapashu360.com" className="heading ">
                info@merapashu360.com
              </a>
            </div>
          </div>

          <div className="c_us__location-form">
            <div className="c_us__location">
              <p className="subheading">{t("reach_out")}</p>
              <p className="heading">{t("corp_office")}</p>
              <p className="c_us__company_address">
                {t("mp_pvt_ltd")} <br /> {t("office_add")}
              </p>
              <p className="c_us__company_timing">
                {t("openfrom")} <br />
                <a href="tel:9971182446"> {t("call")}</a>
              </p>
              <a
                href="https://maps.app.goo.gl/9E7JcTyP1ZXbHbfj9"
                className="c_us__company_location"
              >
                {t("view_on_map")}
              </a>

              <div className="c_us__company_map"></div>
            </div>

            <div className="c_us__form">
              <p className="heading">{t("contact_us")}</p>
              {!submitFormRes?.contactUs?.success ? (
                <form onSubmit={(e) => handleContactUsSubmit(e)}>
                  <label htmlFor="email">{t("email")} </label> <br />
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="abc@gmail.com"
                    required
                  />{" "}
                  <br />
                  <label htmlFor="pNo"> {t("mobile_number")}</label> <br />
                  <input
                    type="text"
                    id="pNo"
                    name="pNo"
                    pattern="[0-9]{10}"
                    value={phoneNumber}
                    maxLength={10}
                    onChange={(e) => {
                      handlePhoneNumberChange(e);
                    }}
                    placeholder="99999 88888"
                    required
                  />{" "}
                  <br />
                  <label htmlFor="subjec">{t("subject")}</label> <br />
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    placeholder="Subject"
                    required
                  />{" "}
                  <br />
                  <label htmlFor="message">{t("message")}</label> <br />
                  <textarea
                    required
                    id="message"
                    name="message"
                    cols={24}
                    rows={6}
                  />{" "}
                  <br />
                  <div className="termsConditions">
                    <input type="checkbox" id="checkbox" required defaultChecked
                    style={{
                      width:"6%",
                      boxShadow:"none",
                      height:"20%"

                     }} />
                    <label className="checkbox" htmlFor="checkbox" style={{ fontSize:"14px"}}>
                      {" "}
                      I agree to all the     {" "}
                      <a href="https://merapashu360.com/terms-of-service">
                        Terms and Conditions
                      </a>
                      .
                    </label>
                  </div>
                  <br />
                  <Button
                    primary
                    rightArrow
                    text={
                      submitFormLoading ? t("submitting") : t("submit_form")
                    }
                    disabled={submitFormLoading}
                  />
                </form>
              ) : (
                <div className="c_us__thankyou">
                  <img src={done} alt="Done" />
                  <p className="c_us__heading">
                    Thank you for submitting the form
                  </p>
                  <p className="c_us__sub">
                    Our team will get back to you soon
                  </p>

                  <div className="c_us__thankyou-button">
                    <Button
                      text="SUBMIT ANOTHER FORM"
                      primary
                      rightArrow
                      to="/contact-us"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <HubLocations />
    </>
  );
}

export default ContactUs;
